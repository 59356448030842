<template>
  <div id="complain-management">

    <table-action-row
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; getList() }"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column :label="$t('DATE')" width="100">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(DATE_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('TIME')" width="100">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(TIME_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('TYPE')" width="200">
        <template slot-scope="{row}">
          {{ getType(row) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('DOCUMENT_LINK')" width="150">
        <template slot-scope="{row}">
          <el-link 
            type="primary"
            @click="goToDocumentLink(row)"
          >
            {{ $t("CLICK_TO_OPEN") }}
          </el-link>
          <i 
            class="custom-icon mini pointer icon-copy"
            @click="copyLink(row)"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMPLAINT_TITLE')" :width="isMobile ? 200 : null">
        <template slot-scope="{row}">
          {{ row.subject }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="120"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item 
                  @click.native="goTo(`/bo/complain/detail/${row.id}`)"
                >
                  {{ $t("VIEW") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js';
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES } from "@/constants";
import { getComplainList } from "@/api/complain"
import { fallbackCopyTextToClipboard } from "@/utils/helpers.js";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  showSuspended: true
};

export default {
  name: "ComplainManagement",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      // Data
      list: [],
      moment,
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    getList() {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      getComplainList(this.listQuery)
      .then(res => {
        if (res.status != 'ok') return
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
    goToDocumentLink(row) {
      let path
      if (row.advertisement != null) { 
        path = `/bo/advertisement/detail/0/${row.advertisement.id}/0` 
      } else if (row.article != null) {
        path = `/${this.getMetaTitle(row.article)}`
      } else if (row.p2pTransaction != null) {
        path = `/bo/p2p-market/transaction/${row.p2pTransaction.transactionRef}`
      }
      this.goTo(path, true)
    },
    copyLink(row) {
      let path
      if (row.advertisement != null) { 
        path = `/bo/advertisement/detail/0/${row.advertisement.id}/0` 
      } else if (row.article != null) {
        path = `/${this.getMetaTitle(row.article)}`
      } else if (row.p2pTransaction != null) {
        path = `/bo/p2p-market/transaction/${row.p2pTransaction.transactionRef}`
      }
      const route = this.$router.resolve(path)
      const link = new URL(route.href, window.location.origin).href
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(link)
      } else {
        navigator.clipboard.writeText(link)
      }
      this.$notify({
        message: this.$t("COPY_TO_CLIPBOARD"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
    },
    getType(row) {
      let code = ""
      if (row.advertisement != null) { 
        code = "ADVERTISEMENT"
      } else if (row.article != null) {
        code = "ARTICLE" 
      } else if (row.p2pTransaction != null) {
        code = "P2P_TRANSACTION"
      }

      return this.$t(code)
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#complain-management {
  position: relative;
  @include newsily-bo-table;
  .action-button,
  .icon-copy {
    float: right;
  }
}
</style>