import request from "@/utils/request";

export async function getComplainList(postData) {
  return await request({
    url: '/complain/list',
    method: "post",
    data: postData,
  });
}

export async function createComplain(postData) {
  return await request({
    url: '/complain/create',
    method: "post",
    data: postData,
  });
}

export async function getComplainById(id) {
  return await request({
    url: `/complain/${id}`,
    method: "get",
  });
}

